import { React, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  CircularProgress,
  DialogContent,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import TempSensor from '../TempSensor';
import useUserStore from '../../../zustand/user.store';

export default function AdminTempSensors({
  tempData,
  processedData,
  setApproveIsLoading,
  handleProcessedAdded,
}) {
  const [tempRows, setTempRows] = useState([]);
  const [tempIndex, setTempIndex] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const user = useUserStore((context) => context.user);

  const tempColumns = [
    { field: 'id', headerName: 'Index', width: 100 },
    {
      field: 'alias',
      headerName: 'Alias',
      width: 300,
      renderCell: (params) => (
        <Button
          onClick={() => {
            // The database entries are 1 indexed, rather than 0 indexed.
            setTempIndex(params.id);
          }}
        >
          {params.value}
        </Button>
      ),
    },
    { field: 'user', headerName: 'User', width: 200 },
    { field: 'time_submitted', headerName: 'Time submitted', width: 200 },
    {
      field: 'approve',
      headerName: 'Approve for processing',
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => {
              approveEntry(params.row.callData);
            }}
            disabled={params.row.isDisabled}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: 'reject',
      headerName: 'Reject',
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => {
            rejectEntry(params.row.alias);
          }}
          disabled={params.row.isDisabled}
        >
          {params.value}
        </Button>
      ),
    },
  ];

  const checkMembership = (id) => {
    let matchFound = false;

    for (let i = 0; i < processedData?.length; i++) {
      let current = processedData[i];

      if (current.uniprotID === id) {
        matchFound = true;
        break;
      }
    }

    return matchFound;
  };

  const constructRows = (data) => {
    const rowsToReturn = [];

    for (let i = 0; i < data.length; i++) {
      let current = data[i];

      rowsToReturn.push({
        id: i,
        alias: current.SK,
        user: current.user,
        time_submitted: new Date(current.timeSubmit).toLocaleString(),
        approve: 'Approve',
        reject: 'Reject',
        callData: current,
        isDisabled: checkMembership(current.SK),
      });
    }

    return rowsToReturn;
  };

  const makeSnackbar = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
      preventDuplicate: true,
    });
  };

  useEffect(() => {
    if (tempData) {
      let result = constructRows(tempData);
      setTempRows(result);
    }
  }, [tempData]);

  const approveEntry = (callBody) => {
    const { PK, SK, ...rest } = callBody;
    const dataToSend = {
      family: callBody.family,
      uniProtID: SK,
      ...rest 
    }

    setApproveIsLoading(true);
    fetch(
      'https://7lne7vyapi.execute-api.us-east-2.amazonaws.com/dev/addNewSensor',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: user.getSignInUserSession().getIdToken().getJwtToken(),
        },
        body: JSON.stringify(dataToSend),
      }
    ).then((res) => {
      setApproveIsLoading(false);

      switch (res.status) {
        case 400:
          makeSnackbar(`Error - ${callBody.uniProtID} is invalid.`, 'error');
          break;
        case 409:
          makeSnackbar(
            `Error - ${callBody.uniProtID} already exists.`,
            'error'
          );
          break;
        case 500:
          res
            .json()
            .then((body) =>
              makeSnackbar(
                `Error - ${callBody.uniProtID} error on xref pipeline. Provided error: ${body}`,
                'error'
              )
            );
        case 202:
          handleProcessedAdded(
            callBody.uniProtID,
            callBody.family,
            callBody.about.alias
          );
          makeSnackbar(`Successfully created ${callBody.uniProtID}`, 'success');
      }
    });
  };

  const rejectEntry = (sensorId) => {
    fetch(
      `https://7lne7vyapi.execute-api.us-east-2.amazonaws.com/dev/deleteTemp?sensorId=${sensorId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: user.getSignInUserSession().getIdToken().getJwtToken(),
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          let copy = tempRows.filter(function (obj) {
            obj.alias !== sensorId;
          });
          let formedRows = constructRows(copy);
          setTempRows(formedRows);
          enqueueSnackbar(
            `Successfully rejected sensor ${sensorId}, please let the end user know.`,
            { variant: 'success', preventDuplicate: true }
          );
        } else {
          enqueueSnackbar('Error on rejecting sensor from API, check logs.', {
            variant: 'error',
            preventDuplicate: true,
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar('Error on rejecting sensor from API, check logs.', {
          variant: 'error',
          preventDuplicate: true,
        });
      });
  };

  return (
    <>
      <Grid item xs={8} mt={6}>
        <Typography variant="h5">Submitted data pending review</Typography>
      </Grid>

      {/* Temp Sensor Table  */}
      <Box
        sx={{
          height: 220,
          width: '70%',
          mt: 2,
        }}
      >
        <DataGrid
          rows={tempRows}
          columns={tempColumns}
          autoPageSize
          rowsPerPageOptions={[5]}
          density="compact"
        />
      </Box>

      {/* Placeholder  */}
      <Box
        sx={{
          width: '95%',
          mt: 2,
        }}
      >
        {tempIndex !== null ? (
          <TempSensor data={tempData[tempIndex]} />
        ) : (
          <h3>Select a temp sensor</h3>
        )}
      </Box>
    </>
  );
}
