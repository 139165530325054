import React from 'react';

import { Box, Grid, Typography, Link } from '@mui/material';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function Contact() {
  return (
    <Box
      sx={{
        marginLeft: { xs: '10vw', sm: '35vw', md: '30vw' },
        marginRight: { xs: '10vw', sm: '5vw', md: '15vw' },
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 24, sm: 28, md: 32 } }}
        fontWeight="300"
        gutterBottom
      >
        Contact
      </Typography>
      <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 18 } }} mb={2}>
        If you have any questions or spot any inaccuracies with groov<sup>DB</sup> please
        feel free to contact us on Github or LinkedIn.
      </Typography>

      <Link href="https://linkedin.com/company/groovbio" target="_blank" mr={3}>
        <LinkedInIcon
          sx={{
            width: '45px',
            height: '45px',
            '&:hover': { color: '#0080ff' },
          }}
        />
      </Link>


      <Link href="https://github.com/simonsnitz" target="_blank">
        <GitHubIcon
          sx={{
            width: '45px',
            height: '45px',
            color: '#171515',
            '&:hover': { color: '#5b5c5e' },
          }}
        />
      </Link>
    </Box>

  );
}
